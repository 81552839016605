.overall-container {
  max-width: 590px;
  margin: 0 auto;
  padding: 0 10px;
  box-sizing: border-box;
}

article > div {
  margin-bottom: 10%;
}

article .live-player-content h6 {
  margin: 0;
  text-align: center;
  font-size: small;
  font-weight: 100;
  color: #585555;
}

article .description {
  text-align: center;
}

article .social-links {
  text-align: center;
}

article .social-links * {
  padding: 20px;
  margin: 10px;
}

a {
  color: rgb(175, 238, 255);
  text-decoration: none;
}

header {
  color: #34568b;
  margin-top: 5%;
  text-align: center;
  margin-bottom: 20px;
}

header * {
  margin: 0;
}

footer {
  font-size: 40%;
  color: white;
  text-align: center;
  position: fixed;
  padding-top: 10px;
  left: 0;
  bottom: 0;
  width: 100%;
  color: #34568b;
  background-color: #45b8ac;
}

@media(max-width: 600px) {
  .social-links {
    max-width: 300px;
    text-align: center;
  }

  article .social-links * {
    width: 15rem;
  }

  article > div {
    margin: 0 auto;
  }
}

@media(min-width: 300px) {
  article > div {
    margin-bottom: 17%;
  }
}

@media(min-width: 350px) {
  article {
    min-height: 350px;
  }

  article > div {
    margin-bottom: 10%;
  }
}

@media(min-width: 400px) {
  header {
    margin-top: 10%;
    text-align: center;
  }

  .overall-container {
    padding: 0 20px;
  }
}

@media(min-height: 800px) {
  article {
    min-height: 600px;
  }
  
  article > div {
    margin-bottom: 7%;
  }
}

@media(min-height: 1024px) {
  footer {
    font-size: 70%;
  }
}

@media(min-height: 1365px) {
  header {
    margin-bottom: 15%;
    font-size: 150%;
  }

  article {
    min-height: 868px;
  }

  article > div {
    margin-bottom: 15%;
  }

  footer {
    font-size: 80%;
  }
}
